<template>
  <main class="grid lg:grid-cols-2 place-items-center pt-16 pb-8 md:pt-8">
    <div class="p-24 md:order-1 hidden md:block">
      <NuxtImg
        class="transition-transform transform hover:scale-125"
        src="hero.png"
        alt="Starship starts the engine"
        loading="eager"
        format="webp"
        sizes="80vw sm:300px lg:512px"
      />
    </div>

    <div>
      <h1 class="text-5xl lg:text-6xl xl:text-7xl font-bold lg:tracking-tight">
      {{ $t('heroTitle')}}
      </h1>
      <p class="text-lg mt-4 text-slate-600 max-w-xl">
        {{ $t('heroDesc')}}
      </p>
      <div class="mt-6 flex flex-col sm:flex-row gap-3">
        <NuxtLink class="router-link-active router-link-exact-active rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800  border-2 border-transparent" to="#features">{{ $t('heroCTAMore')}}</NuxtLink>
        <NuxtLink
          class="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-white border-2 border-black hover:bg-gray-100 text-black"
          to="#contact">{{ $t('heroCTAContact')}}</NuxtLink>
      </div>
    </div>
  </main>
</template>
<script setup lang="ts">
const { t } = useI18n()
</script>
